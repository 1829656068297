export const LIST_BIDDING = 'LIST_BIDDING'
export const GET_BIDDING = 'GET_BIDDING'
export const GET_SETTING = 'GET_SETTING'
export const UPDATE_BIDDING = 'UPDATE_BIDDING'
export const DELETE_BIDDING = 'DELETE_BIDDING'
export const LIST_BIDDING_SUCCESS = 'LIST_BIDDING_SUCCESS'
export const GET_BIDDING_SUCCESS = 'GET_BIDDING_SUCCESS'
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS'
export const UPDATE_BIDDING_SUCCESS = 'UPDATE_BIDDING_SUCCESS'
export const DELETE_BIDDING_SUCCESS = 'DELETE_BIDDING_SUCCESS'
export const LIST_BIDDING_FAILED = 'LIST_BIDDING_FAILED'
export const GET_BIDDING_FAILED = 'GET_BIDDING_FAILED'
export const GET_SETTING_FAILED = 'GET_SETTING_FAILED'
export const UPDATE_BIDDING_FAILED = 'UPDATE_BIDDING_FAILED'
export const DELETE_BIDDING_FAILED = 'DELETE_BIDDING_FAILED'
export const TOGGLE_UPDATE_BIDDING = 'TOGGLE_UPDATE_BIDDING'
export const TOGGLE_DELETE_BIDDING = 'TOGGLE_DELETE_BIDDING'
export const TOGGLE_SHOW_BIDDING = 'TOGGLE_SHOW_BIDDING'
export const SELECT_BIDDING = 'SELECT_BIDDING'

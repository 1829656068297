import { bidding } from '../actionType'

const initialState = {
  tableItems: [],
  lastDoc: null,
  loading: false,
  selectedItem: {
    brand: '',
    condition: '',
    dataName: '',
    date: '',
    docID: '',
    finalDatetime: '',
    finalPrice: '',
    finalTotalBid: '',
    finalTotalBidder: '',
    id: '',
    initialDatetime: '',
    initialPrice: '',
    initialTotalBid: '',
    initialTotalBidder: '',
    model: '',
    name: '',
    searchBrand: '',
    searchModel: '',
    year: '',
    session: '',
  },
  setting: {
    notice: '',
  },
  submitButtonDisabled: false,
  updateBiddingToggle: false,
  deleteBiddingToggle: false,
  showBiddingToggle: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case bidding.LIST_BIDDING:
    case bidding.GET_BIDDING: {
      return Object.assign({}, state, { loading: true })
    }
    case bidding.UPDATE_BIDDING:
    case bidding.DELETE_BIDDING: {
      return Object.assign({}, state, {
        loading: true,
        submitButtonDisabled: true,
      })
    }

    case bidding.LIST_BIDDING_SUCCESS: {
      const { tableItems, startAfter, lastDoc } = action.payload
      let currItems = []
      if (startAfter) {
        currItems = state.tableItems
        currItems.pop()
      }
      currItems.push(...tableItems)
      return Object.assign({}, state, {
        tableItems: currItems,
        lastDoc,
        loading: false,
      })
    }
    case bidding.GET_BIDDING_SUCCESS: {
      const { data } = action.payload
      var tableItems = state.tableItems
      var itemIndex = tableItems.findIndex((item) => item.docID === data.docID)
      tableItems[itemIndex] = data
      return Object.assign({}, state, { tableItems, loading: false })
    }
    case bidding.GET_SETTING_SUCCESS: {
      const { data } = action.payload
      return Object.assign({}, state, { setting: data })
    }
    case bidding.UPDATE_BIDDING_SUCCESS: {
      const { id, updateData } = action.payload
      var tableItems = state.tableItems
      var itemIndex = tableItems.findIndex((item) => item.docID === id)
      tableItems[itemIndex] = { ...tableItems[itemIndex], ...updateData }
      return Object.assign({}, state, {
        loading: false,
        submitButtonDisabled: false,
        updateBiddingToggle: false,
        tableItems: tableItems,
      })
    }
    case bidding.DELETE_BIDDING_SUCCESS: {
      const { id } = action.payload
      var tableItems = state.tableItems
      var itemIndex = tableItems.findIndex((item) => item.docID === id)
      tableItems.splice(itemIndex, 1)
      return Object.assign({}, state, {
        loading: false,
        submitButtonDisabled: false,
        deleteBiddingToggle: false,
        tableItems: tableItems,
      })
    }
    case bidding.LIST_BIDDING_FAILED:
    case bidding.GET_BIDDING_FAILED:
    case bidding.UPDATE_BIDDING_FAILED:
    case bidding.DELETE_BIDDING_FAILED: {
      return Object.assign({}, state, {
        loading: false,
        submitButtonDisabled: false,
      })
    }
    case bidding.TOGGLE_UPDATE_BIDDING: {
      const { updateBiddingToggle, selectedItem } = action.payload
      return Object.assign({}, state, {
        updateBiddingToggle,
        selectedItem: { ...initialState.selectedItem, ...selectedItem },
      })
    }
    case bidding.TOGGLE_DELETE_BIDDING: {
      const { deleteBiddingToggle, selectedItem } = action.payload
      return Object.assign({}, state, {
        deleteBiddingToggle,
        selectedItem: { ...initialState.selectedItem, ...selectedItem },
      })
    }
    case bidding.TOGGLE_SHOW_BIDDING: {
      const { showBiddingToggle, selectedItem } = action.payload
      return Object.assign({}, state, {
        showBiddingToggle,
        selectedItem: { ...initialState.selectedItem, ...selectedItem },
      })
    }
    default:
      return state
  }
}
